@font-face {
  font-family: "SemplicitaPro";
  src: url(" ../src/assets/fonts/SemplicitaPro.otf");
}

/* * {
  font-family: "semplicitapro-medium";
} */
* {
  font-family: "Font Awesome 5 Pro", sans-serif !important;
  /* font-weight: 600 !important; */
}

body,
html {
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

.font-weight-600 {
  font-weight: 600;
}

/* login page */
.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background: #ffffff;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.login-container {
  /* background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(113,166,222,1) 50%, rgba(255,255,255,1) 100%); */
  background-color: #dddfe0;
}

.login .btn {
  float: right;
}

.login-form label {
  float: left;
}

/* SIDEBAR */
.sidebar-outer {
  /* display: table; */
  height: 100%;
}

#sidebar-dashboard {
  padding: 8px;
}

.main-sidebar {
  display: table-cell;
  /* width: 320px; */
  padding: 18px 0 25px 24px;
  /* position: fixed; */
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background: linear-gradient(#56bcb2, #1b46b4); */
  /* background: linear-gradient(#3e4378, black); */
  background: #ffffff;
}

.main-sidebar .sidebar-menu ul {
  list-style: none;
  padding: 0;
}

.main-sidebar .sidebar-menu ul li {
  margin-bottom: 7px;
}

.main-sidebar .sidebar-menu ul li a {
  color: black;
  display: flex;
  width: 98%;
  padding-left: 8px;
}

.main-sidebar .sidebar-menu ul li a:hover {
  color: white;
  background-color: #56bcb2;
  border-radius: 5px;
  width: 98%;
}

.main-sidebar .sidebar-menu ul li a:hover .menu-item-icon {
  color: white;
  background-color: #1b46b4;
  border-radius: 5px;
}

.main-sidebar .sidebar-menu ul li a .menu-item-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
}

.main-sidebar .sidebar-menu ul li a .menu-item-name {
  font-weight: 600;
  padding-left: 20px;
  padding-top: 2px;
}

.main-sidebar .sidebar-menu ul li .active {
  color: #ffffff;
  background-color: #56bcb2;
  border-radius: 5px;
  width: 98%;
  padding-left: 8px;
}

.main-sidebar .sidebar-menu ul li .active .menu-item-icon {
  color: white;
  background-color: #1b46b4;
  border-radius: 5px;
}

/* notification */
.bell {
  cursor: pointer;
}

.dropdown-menu {
  /* position: absolute; */
  /* display: none; */
  z-index: 1;
  /* // left: -110px; */
  left: -130px;
  top: 60px;
  min-height: 10px;
  min-width: 10px;
  background-color: none !important;
  width: 280px;
}

.notification-container {
  border: 1px solid black;
  width: 352px;
  position: absolute;
  right: 300px;
  top: 70px;
  background-color: white;
  border-radius: 10px;
  z-index: 10;
}

.notification-header {
  padding-top: 13px;
}

.navbar-main-container {
  position: relative;
  /* background: linear-gradient(#1b46b4, #3e4378); */
  background: #1b46b4;
}

.navbar-welcome-name {
  color: #ffffff;
}

.bell img {
  width: 28px;
}

.user-img-navbar {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 5px;
  padding-right: 0px !important;
}

.user-img-navbar .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /*   
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 5px; */
}

.notification-body {
  padding: 13px;
  border-radius: 8px;
  height: 360px;
  overflow-y: auto;
}

.notification-userData {
  border: 1px solid #00bfb230;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 5px 0px 10px 0px;
}

.notification-name-time {
  display: flex;
  justify-content: space-between;
}

.notification-userName {
  text-align: initial;
  padding-left: 10px;
}

.notification-userMsg {
  text-align: initial;
  font-size: 13px;
  padding-left: 10px;
  color: #847e7e;
}

.notification-time {
  color: #96a1b4;
  font-size: 11px;
  padding-right: 10px;
}

.notification-page {
  box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 40%);
}

.notification-page-header {
  width: 100%;
  display: flex;
  margin-bottom: 13px;
}

.notification-page-main-container {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: 8px;
  padding-top: 35px;
  height: 721px;
  overflow-y: auto;
}

.notification-page h1 {
  float: left;
  font-size: 24px;
  font-weight: 800 !important;
}

.notification-page-userData {
  border-bottom: 1px solid #00bfb230;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px 35px 30px 20px;
}

.notification-page-name-time {
  display: flex;
  justify-content: space-between;
}

.notification-page-userName {
  text-align: initial;
  padding-left: 10px;
  display: flex;
}

.notification-page-userMsg {
  text-align: initial;
  font-size: 13px;
  padding-left: 58px;
  color: #847e7e;
}

.notification-page-time {
  color: #847e7e;
  font-size: 11px;
  padding-right: 10px;
}

.notification-page-cross-btn {
  padding-right: 22px;
  padding-top: 3px;
}

/* Dashboard */
.dashboard-container {
  width: 100%;
  height: 100%;
  background-color: #efefef;
  /* background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(75,127,177,1) 100%); */
}

/* Invite user */
.invite-user-header {
  width: 100%;
  display: flex;
  margin-bottom: 40px;
}

.invite-user-header h1 {
  /* font-size: 15px; */
  float: left;
}

.invite-user {
  width: 100%;
  height: 100%;
  background-color: #efefef;
}

.invite-user-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.invite-user-container .card {
  border: unset;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  width: 40%;
  /* height: 260px; */
}

.send-invite-btn .btn {
  float: right;
  margin-top: 43px;
}
.organization-container-with-email {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}
/* Manage users */
.manage-user {
  width: 100%;
  height: 100%;
  background-color: #efefef;
}

.survey-result-container {
  max-height: 600px;
  overflow-y: auto;
}

/* .coach-clients-page {
  box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 40%);
} */
.clients-page-main-container {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: 8px;
  padding: 0px 14px 14px 14px;
  max-height: 70vh;
  overflow-y: auto;
  display: block;
  background-color: #ffffff;
  border-top: 13px solid #ffffff;
  min-height: 30vh;
}
.manage-users-page-main-container {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: 8px;
  padding: 0px 14px 14px 14px;
  max-height: 65vh;
  min-height: 40vh;
  overflow-y: auto;
  display: block;
  background-color: #ffffff;
  border-top: 13px solid #ffffff;
}
.container-header {
  padding: 20px 30px;

  border: none;
}

.header-headings {
  display: flex;
  justify-content: space-around;
}

.header-headings h5 {
  font-size: 15px;
}

.clients-data-all {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e3e9ef;
}

.clients-data {
  padding-right: 80px;
}

.clients-data-all h5 {
  font-size: 13px;
}

.clients-img .img {
  padding-bottom: 10px;
}

.create-activity-font {
  padding: 0.5rem 1rem !important;
}

.create-activity-font:hover {
  background-color: #56bcb2;
}
.ql-editor {
  min-height: 150px;
  max-height: 250px;
  overflow-y: auto;
}
.dropdown-item {
  background-color: #ffffff !important;
}

.dropdown-item:focus {
  background-color: none !important;
  color: black !important;
}

.dropdown-item:hover {
  background-color: none !important;
}

.duesoon-days {
  padding-right: 20px;
}

.engagements-rates {
  padding-left: 30px;
}

.engagements-rates h5 {
  color: #1b46b4;
}

.form-check .client-checkbox {
  /* width: 25px;
  height: 25px; */
  display: none;
}

table {
  border-collapse: collapse;
  width: 100%;
  padding: 20px !important;
}

/* tr:first-child td:first-child { border-top-left-radius: 100px; } */
td,
th {
  border: 1px solid #dddddd;
  /* text-align: center !important; */
  padding: 8px;
}

th {
  position: sticky;
  top: 0;
  background-color: #f1f5f9;
  z-index: 2;
}

.header-row {
  background-color: #f1f5f9;
  padding: 15px 0px 8px 0px;
  /* border: 16px solid white; */
}

.pic-name-in-table {
  display: flex;
  justify-content: flex-start;
  /* margin-left: -65px; */
}

.client-img-in-table {
  padding-right: 17px;
}

.whole-row-manage-activity-user-list:hover {
  background-color: #f1f5f9;
  cursor: pointer;
}

.main-sidebar {
  border-right: 1px solid lightgrey;
  font-size: medium;
}

/* coach-notification */
/* .coach-notification-page {
  box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 40%);
} */
/* .no-notification-case {
  font-size: 20px;
} */
.coach-clients-data-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #e5dbdb;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.btn {
  box-shadow: none !important;
}

.activity-news-feed-container {
  height: 813px;
  overflow-y: auto;
}

td,
th {
  border: none;
}

#toggle-btn-clients {
  color: #fff !important;
  background-color: none !important;
  border-color: #fff !important;
  padding: 0px;
  border: 0px;
}

#toggle-btn-clients :hover {
  background-color: none !important;
}

.toggle-btn-clients img {
  background: #ffffff !important;
  border-radius: 3px !important;
}

.dropdown-toggle::after {
  display: none !important;
}

/* .dropdown-menu.show {
  width: 50px;
} */

.btn-primary {
  background-color: none;
}

.health-kit {
  width: 100%;
  height: 100%;
  background-color: #efefef;
}

.health-kit .card {
  border: unset;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: 10px;
}

.cards-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cards-main-container h1 {
  font-size: 42px;
}

.healthKitUpdateCardsFont {
  font-size: 15px;
}

.healthKitUpdateHeaderFont {
  font-weight: 600;
}

.btn-coach {
  color: white;
  background-color: #00bfb2;
  border: unset;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
}

.modal-open {
  padding-right: 0 !important;
}

.goalSelectorField {
  background-color: #ffffff;
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem !important;
  padding: 5px;
  max-width: 380px;
}

.btn-block {
  background-color: red;
  padding: 10px 0px 10px 0px;
}

.user-profile-img {
  border: 2px solid #e5dbdb;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-left: 127px;
  object-fit: cover;
}

.edit-profile-img-icon {
  margin-top: -355px;
  margin-left: 284px;
  cursor: pointer;
}

.image-file > input {
  display: none;
}

.modal-body-unblock-list {
  max-height: 500px;
  overflow-y: auto;
  margin: 10px 0px 10px 10px;
  padding: 0px !important;
}

.btn-unblock-user {
  color: white;
  background-color: #00bfb2;
  border: unset;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  width: 136px;
}

.chat-and-event-btn {
  /* box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 40%); */
  width: 100%;
}
.add-event-btn {
  display: flex;
  justify-content: space-between;
  margin: 2% 2% 0 2%;
}
.add-event-btn button {
  background-color: #3174ad !important;
  border: unset;
  padding: 10px 20px;
  border-radius: 5px;
}

.journeys-heading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}
.Journeys-dates-container {
  border-bottom: 1px solid #d9e0e8;
}
.Journeys-dates-data {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}
.Journeys-dates-data p {
  font-size: 20px;
  color: #1b46b4;
}
.Journeys-dates-container h6 {
  display: flex;
  margin-bottom: 30px;
}
.Journeys-dates-param {
  display: flex;
  justify-content: space-evenly;
}
.Journeys-dates-param p {
  margin-bottom: 0px !important;
  color: #847e7e;
}

.activity-journey-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.activity-journey-dates {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.chat-client-btn button {
  color: white;
  background-color: #00bfb2;
  border: unset;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 45px 15px 0px 0px;
  float: right;
}

/* MANAGE QUESTIONSS */
.manage-questions {
  width: 100%;
  height: 100%;
  background-color: #efefef;
}

.questions-page-main-container {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: 8px;
  padding: 0px 14px 14px 14px;
  text-align: left;
  display: block;
  background-color: #ffffff;
  border-top: 13px solid #ffffff;
}

.question-text p {
  float: left;
  font-size: 18px;
}

.question-text-input {
  width: 100% !important;
}

.questionCategorySelectorFieldForm {
  float: left;
}

.question-category-form-group {
  display: flex;
  float: left;
  flex-direction: column;
}

.questions-form-end-buttons {
  display: flex;
  justify-content: flex-end;
}

.selectedOption {
  text-align: center;
}

.value-and-scoring-inputs {
  margin-top: 50px;
  text-align: center;
}

.valueAndScoreSelectorField {
  background-color: #ffffff;
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem !important;
  padding: 5px;
  max-width: 380px;
  margin-left: 40px;
}

.add-value-main-container {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: 8px;
  padding: 0px 14px 14px 14px;
  width: 100%;
  display: block;
  background-color: #ffffff;
  border-top: 13px solid #ffffff;
  margin-bottom: 46px;
}

.narativeResSelectorField {
  background-color: #ffffff;
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem !important;
  padding: 5px;
  max-width: 380px;
  margin: 0px 40px;
  height: 37px;
}

#parent-question-field {
  width: 500px !important;
}

#parent-question-field option {
  width: 300px !important;
}

.add-question-modal-body {
  max-height: 790px;
  overflow-y: auto;
  overflow-x: hidden;
}

.options-container {
  max-height: 172px;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 730px;
  word-break: break-word;
}

.narative-response-data {
  max-width: 315px;
  word-break: break-word;
}

.options-modal-main-container {
  padding: 0px 14px 14px 14px;
  max-height: 330px;
  min-height: 233px;
  overflow-y: auto;
  display: block;
  background-color: #ffffff;
  border-top: 13px solid #ffffff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* upload content */
.mp4-input {
  width: 100% !important;
}

.ql-tooltip {
  left: 0 !important;
}

.ql-editor strong {
  font-weight: bold !important;
}

.goal-for-content {
  width: 40%;
}

.upload-buttons {
  display: flex;
  justify-content: flex-end;
}

.goal-for-plan {
  width: 100%;
}

.upload-content-modal-body {
  max-height: 650px;
  overflow-y: auto;
}

.manage-content-main-container {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: 8px;
  padding: 0px 14px 14px 14px;
  max-height: 55vh;
  overflow-y: auto;
  display: block;
  background-color: #ffffff;
  border-top: 13px solid #ffffff;
  margin-top: 50px;
  z-index: 0;
}

/* COACH REPORT */
.coach-report-page {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.coach-report-table {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: 8px;
  padding: 0px 14px 14px 14px;
  max-height: 70vh;
  overflow-y: auto;
  display: block;
  background-color: #ffffff;
  border-top: 13px solid #ffffff;
  width: 900px;
}

/* ORGANIZATION REPORT */
.organization-report-main {
  border-radius: 8px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  background-color: #ffffff;
  margin: 50px;
  padding: 20px;
  max-height: 70vh;
  overflow-y: auto;
}

.organization-report-page {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.organization-report-table {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: 8px;
  padding: 0px 14px 14px 14px;
  display: block;
  background-color: #ffffff;
  border-top: 13px solid #ffffff;
  width: 650px;
}

.average-score p {
  text-align: left;
  font-weight: bolder;
  font-size: 20px;
  margin-bottom: 0px;
}

.report-engagement-heading {
  font-weight: bold;
  font-size: 20px;
  padding-top: 40px;
}

.organization-report-filter {
  width: 30%;
}

.organization-report-filter-heading {
  font-weight: bold;
  font-size: 20px;
}

.No-data-statement {
  margin: 5%;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5% auto;
}
.Select {
  position: absolute;
  /* Position the dropdown absolutely within the container */
  top: 0;
  /* Align the dropdown to the top of the container */
  left: 0;
  /* Align the dropdown to the left of the container */
  z-index: 1;
  /* Set a higher z-index than the content table */
}
.coach-login-history-body {
  max-height: 40vh;
  overflow-y: auto;
}
/* Style the scrollbar */
*::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Track */
*::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 12px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
  border-radius: 12px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar handle on hover */
  cursor: pointer;
}
/* feedback */
.feedback-message {
  padding-left: 12px;
  color: #1b46b4;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
/* MEDIA QUERIES */
/* General styles */
/* body {
  font-family: Arial, sans-serif;
}

.dashboard-main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.row {
  display: flex;
  flex: 1;
} */

/* Styles for medium screens like MacBooks */
/* @media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .dashboard-main {
    padding: 10px;
  }

  .row {
    flex-direction: row;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: 200px;
  }

  .col-md {
    flex: 1;
  }

  .p-0 {
    padding: 0 !important;
  }
} */

/* @media only screen and (min-width: 1440px) and (max-width: 1440px) and (min-height: 900px) and (max-height: 900px) {
  .dashboard-main {
    padding: 10px;
  }

  .col-md-auto {
    width: 250px; 
  }

  .col-md {
    flex: 1;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1440px) {
  .dashboard-main {
    padding: 10px;
  }

  .col-md-auto {
    width: 220px; 
  }

  .col-md {
    flex: 1;
  }
} */
